@tailwind components;
@tailwind utilities;

// ==== custom variable start ====
:root {
  --primary-color: #1d828e;
  --secondary-color: #00d4e7;
  --grayish-black-color: #2d2d2d;
  --dark-gray-color: #7b7a7e;
  --gray-color: #9b9b9d;
  --secondary-gray-color: #ededed;
  --text-gray-color: #403d3b;
  --background-gray-color: #f4f4f6;
  --secondary-background-gray-color: #bfbfbf;
  --light-gray-color: #c6c6c6;
}
// ==== custom variable end ====

// ==== custom css start ====

// ==== custom css end ====
