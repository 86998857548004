.terms_container {
  padding: 130px 0px;

  & .main_title {
    font-size: 30px;
    line-height: 1.3;
    font-weight: 600;
    font-weight: 600;
    color: var(--primary-color);
  }

  & h2 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
    color: var(--grayish-black-color);
  }

  & h3 {
    font-size: 19px;
    font-weight: 600;
    color: var(--grayish-black-color);
  }

  & p {
    font-size: 16px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .terms_container {
    padding: 30px 0px;
  }
}

@media only screen and (max-width: 687px) {
  .terms_container {
    padding: 0px 0px;
  }
}
